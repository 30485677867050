import { Image, Surface, Text, ThemeProvider } from '@change/tomato-core-ui';

import { Translate } from '@change-corgi/core/react/i18n';

import type { Props } from '../../shared/types';
import { AmountSelectorV2 } from '../AmountSelector/V2';
import { HeroLayout } from '../HeroLayout';
import { TermSelector } from '../TermSelector';

export function MembershipAwarenessV2(props: Props): React.JSX.Element {
	const { version } = props;
	return (
		<ThemeProvider>
			<Surface as="section" color="surface-neutral" className="overflow-hidden rounded-[16px]">
				<HeroLayout
					ratio={version === 'v2home' ? '1/2' : '2/5'}
					media={
						<div className="relative h-full overflow-hidden">
							<div className="h-[100%] w-[100%] sm:max-md:relative md:absolute">
								<Image
									alt="main onsite image"
									src="https://static.change.org/membership/m-onsite.png"
									className="h-full w-full object-cover"
									sx={{
										clipPath: [
											'ellipse(70% 100% at top)',
											version === 'v2home' ? 'ellipse(100% 120% at left)' : 'ellipse(100% 70% at left)',
										],
									}}
								/>
							</div>
						</div>
					}
				>
					<div
						className={`${version === 'v2home' ? 'sm:max-md:m-[16px] md:m-[96px]' : 'sm:max-md:m-[16px] md:m-[40px]'} flex flex-col gap-[8px]`}
					>
						<Text className="text-size-heading-large" color="stroke-strong" variant="heading" fontWeight="bold">
							<Translate value="corgi.membership.onsite_placement.heading" />
							<div className="ms-[3px] inline-block h-[10px] w-[10px] rounded-full bg-content-highlight" />
						</Text>
						<div>
							<Text variant="body" color="stroke-strong" fontWeight="light">
								<Translate value="corgi.membership.onsite_placement.subtext" />
							</Text>
						</div>
						<div className="mt-[10px]">
							<TermSelector {...props} />
							<AmountSelectorV2 {...props} />
						</div>
					</div>
				</HeroLayout>
			</Surface>
		</ThemeProvider>
	);
}

import { useCallback, useMemo } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';
import { useNavigate } from '@change-corgi/core/react/router';
import { useTracking } from '@change-corgi/core/react/tracking';

import {
	useMembershipAwarenessFormData,
	useMembershipAwarenessFormFcm,
	useMembershipAwarenessFormState,
} from '../../../../formContext';
import type { Props } from '../../../../shared/types';

type OnClickParams = { amount?: number; buttonName?: string };

export type Result = ModelHookResult<
	{
		buttonAmounts: ReadonlyArray<{ amount: number; amountLocalized: string }>;
		selectedAmount: { amount: number; amountLocalized: string };
		buttonText?: string;
		displayPayWithText: boolean;
	},
	{
		onClick: (params: OnClickParams) => void;
	}
>;

// eslint-disable-next-line max-lines-per-function
export function useAmountSelector({ sourceLocation, slug, signed }: Props): Result {
	const track = useTracking();
	const navigate = useNavigate();
	const { amountToBaseUnits, amountFromBaseUnits, translate, localizeCurrency } = useI18n();
	const {
		subscriptionsAmounts: buttonAmounts,
		subscriptionsDefaultAmount: defaultAmount,
		currency,
	} = useMembershipAwarenessFormData();
	const { subscriptionConfig, yearlyContributionsAmounts } = useMembershipAwarenessFormFcm();
	const [{ paymentTerm }] = useMembershipAwarenessFormState();

	const selectedAmount = useMemo(() => {
		const isYearlyEnabled = yearlyContributionsAmounts?.enabled && paymentTerm === 'yearly';

		if (isYearlyEnabled && yearlyContributionsAmounts.amounts.length > 1) {
			return {
				amount: amountFromBaseUnits(yearlyContributionsAmounts.amounts[1], currency),
				amountLocalized: localizeCurrency(yearlyContributionsAmounts.amounts[1], currency, {
					currencyDisplay: 'narrowSymbol',
					amountInBaseUnits: true,
				}),
			};
		}

		return defaultAmount;
	}, [currency, defaultAmount, amountFromBaseUnits, localizeCurrency, paymentTerm, yearlyContributionsAmounts]);

	const yearlyAmounts = useMemo(() => {
		return yearlyContributionsAmounts?.enabled
			? yearlyContributionsAmounts.amounts.map((amount) => ({
					amount: amountFromBaseUnits(amount, currency),
					amountLocalized: localizeCurrency(amount, currency, {
						currencyDisplay: 'narrowSymbol',
						amountInBaseUnits: true,
					}),
				}))
			: [];
	}, [yearlyContributionsAmounts, amountFromBaseUnits, currency, localizeCurrency]);

	const displayAmounts = useMemo(() => {
		const isYearlyEnabled = yearlyContributionsAmounts?.enabled && paymentTerm === 'yearly';
		return isYearlyEnabled ? yearlyAmounts : buttonAmounts;
	}, [yearlyContributionsAmounts, paymentTerm, yearlyAmounts, buttonAmounts]);

	const onClick = useCallback(
		({ amount, buttonName }: OnClickParams) => {
			void track(`${sourceLocation}_membership_ask_click`, { button_clicked: buttonName });

			if (subscriptionConfig?.thirdPartySubscriptionService) {
				void track('membership_ask_click_third_party_subscription_service');
				navigate(subscriptionConfig.thirdPartySubscriptionService);
				return;
			}

			const path = slug ? `/p/${encodeURIComponent(slug)}/psf/membership` : '/member/join';
			navigate(
				`${path}?source_location=${sourceLocation}&amount=${amountToBaseUnits(amount ?? selectedAmount.amount, currency)}&payment_term=${paymentTerm}`,
				{ state: { signed, slug } },
			);
		},
		[
			track,
			sourceLocation,
			subscriptionConfig?.thirdPartySubscriptionService,
			slug,
			navigate,
			amountToBaseUnits,
			selectedAmount.amount,
			currency,
			paymentTerm,
			signed,
		],
	);

	return {
		data: {
			selectedAmount,
			buttonAmounts: displayAmounts,
			buttonText: translate('corgi.membership.awareness.cta'),
			displayPayWithText: !subscriptionConfig?.thirdPartySubscriptionService,
		},
		actions: {
			onClick,
		},
	};
}

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';
import type { FCM_SUBSCRIPTION_CONFIGURATION, FcmConfigValueType } from '@change-corgi/fcm/configs';

import type { MembershipAwarenessFormData } from '../../types';
import type { MembershipAwarenessFormDataQuery } from '../getMembershipFormData.graphql';

export function normalize(
	{ i18n: { amountFromBaseUnits, localizeCurrency } }: UtilityContext,
	{
		data,
		subscriptionConfig,
	}: {
		data: MembershipAwarenessFormDataQuery;
		subscriptionConfig: NonNullable<FcmConfigValueType<typeof FCM_SUBSCRIPTION_CONFIGURATION>>;
	},
): MembershipAwarenessFormData {
	if (!data.session.country.membershipProgram && !subscriptionConfig.thirdPartySubscriptionService) {
		throw new Error("No membership program for current user's country");
	}

	const currency = data.session.country.currency?.code || 'USD';

	const subscriptionsAmounts = subscriptionConfig.amounts.map((baseAmount) => {
		const amount = amountFromBaseUnits(baseAmount, currency);
		return {
			amount,
			amountLocalized: localizeCurrency(amount, currency, {
				currencyDisplay: 'narrowSymbol',
				amountInBaseUnits: false,
			}),
		};
	});

	const subscriptionsDefaultAmount = (() => {
		const amount = amountFromBaseUnits(subscriptionConfig.defaultAmount, currency);
		return {
			amount,
			amountLocalized: localizeCurrency(amount, currency, {
				currencyDisplay: 'narrowSymbol',
				amountInBaseUnits: false,
			}),
		};
	})();

	return {
		currency,
		...{
			subscriptionsAmounts,
			subscriptionsDefaultAmount,
		},
	};
}
